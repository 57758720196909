import React, { Component } from "react";
import Login from "./component/Login";
import HomePage from "./component/HomePage";
import PrivateRoute from "./component/PrivateRoute";
import { BrowserRouter, Route } from "react-router-dom";
export default class App extends Component {
  render() {
    return (
      <div>
        <BrowserRouter>
          <div>
            <Route exact path="/" component={Login} />
            <PrivateRoute path="/homepage" component={HomePage} />
          </div>
        </BrowserRouter>
      </div>
    );
  }
}
