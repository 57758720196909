import React from "react";
import "../../App.css";
import Day from "./Day";
export default class Week extends React.Component {
  componentWillReceiveProps(newprops) {
    if (newprops !== this.props) {
      this.setState({
        ...this.state,
        ...newprops
      });
    }
  }
  render() {
    let days = [];
    let { date } = this.props;

    const { month, selected, select } = this.props;

    for (var i = 0; i < 7; i++) {
      let day = {
        name: date.format("dd").substring(0, 1),
        number: date.date(),
        isCurrentMonth: date.month() === month.month(),
        isToday: date.isSame(new Date(), "day"),
        date: date
      };
      days.push(<Day appointments={this.props.appointments} day={day} key={'day' + i} selected={selected} select={select} />);

      date = date.clone();
      date.add(1, "day");
    }

    return (
      <div className="row week" key={days[0]}>
        {days}
      </div>
    );
  }
}
