import React from "react";
import "flatpickr/dist/themes/airbnb.css";
import Flatpickr from "react-flatpickr";
import AppointmentModal from "./AppointmentModal"
export default class DatePickerComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: "",
      endDate: new Date()
    };
    this.state = {
      ...this.state,
      ...this.props
    };
  }

  componentWillReceiveProps(newprops) {
    if (newprops !== this.props) {
      this.setState({
        ...this.state,
        ...newprops
      });
    }
  }

  componentDidMount() {
    this.setState({
      startDate: this.state.starttime

    });
  }

  handleStartChange = date => {
    this.setState({
      startDate: date
    });
    console.log("start date: " + this.state.startDate);
  };
  handleEndChange = date => {
    this.setState({
      endDate: date
    });
  };

  render() {
    return (
      <div>
        <div className="dateModalTime">
          <p>Start</p>
          <Flatpickr
            data-enable-time
            value={this.state.startDate}
            onChange={startDate => {
              this.handleStartChange(startDate);
            }}
          />
        </div>
        <div className="dateModalTime">
          <p>End</p>
          <Flatpickr
            data-enable-time
            value={this.state.endDate}
            onChange={endDate => {
              this.setState({ endDate });
            }}
          />
        </div>
        <AppointmentModal
          starttime_change={this.state.startDate}
          endtime_change={this.state.endDate}
        />
      </div>
    );
  }
}
