import React, { Component } from "react";
import logo from "../images/Login_logo.png";
import "../App.css";
import { Button, Form, FormGroup, Input } from "reactstrap";
import apicall from "../util/apicall";
export default class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: ""
    };
  }

  handleSubmit = event => {
    apicall.getOauthToken(this.state.username, this.state.password, this.props);
    event.preventDefault();
  };

  handleChange = event => {
    const target = event.target;
    const name = target.name;
    const value = target.value;
    this.setState({
      [name]: value
    });
  };

  render() {
    return (
      <div className="App float-center">
        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
          <Form>
            <FormGroup className="formGrouping">
              <Input
                name="username"
                type="email"
                id="usernamelogin"
                required
                placeholder="Email"
                onChange={this.handleChange}
              />
            </FormGroup>
            <FormGroup>
              <Input
                name="password"
                id="usernamepass"
                type="password"
                required
                placeholder="password"
                onChange={this.handleChange}
              />
            </FormGroup>
            <Button
              type="submit"
              className="formButton"
              onClick={this.handleSubmit}
              color="primary"
              size="lg"
            >
              Log In
            </Button>
          </Form>
        </header>
      </div>
    );
  }
}
