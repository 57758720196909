import React, { Component } from "react";
import "../App.css";
import apicall from "../util/apicall";
import arrow from "../images/arrow.png";
import CompanyModal from "./CompanyModal";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
export default class AppointmentModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDatePicker: false,
      nestedModal: false,
      closeAll: false,
      actionData: [],
      checked: false,
      saveResponse: [],
      updateCompanyResponse: [],
      companyResponse: [],
      nestedDateModal: false,
      salesModal: false,
      meetingsModal: false,
      appointment: {}
    };
    this.state = {
      ...this.state,
      ...this.props
    };
  }

  componentWillReceiveProps(newprops) {
    if (newprops !== this.props) {
      this.setState({
        ...this.state,
        ...newprops
      });
    }
  }

  onbuttonclick = () => {
    this.setState({
      modal: true
    });
    console.log(this.state);
  };

  toggle = () => {
    this.state.toggle(this.state.index);
  };

  showDatePicker = () => {
    this.setState({
      showDatePicker: !this.state.showDatePicker
    });
  };

  toggleNested = () => {
    this.setState({
      nestedModal: !this.state.nestedModal,
      closeAll: false
    });
  };

  handleSalesButton = () => {
    this.setState({
      salesModal: !this.state.salesModal
    });
  };

  handleMeetingsButton = () => {
    this.setState({
      meetingsModal: !this.state.meetingsModal
    });
  };

  getCompany = () => {
    if (this.state.companyName !== "" && this.state.companyName !== undefined) {
      apicall
        .getCompanyById(this.state.appointment_company_id, this.props)
        .then(response => {
          this.setState({
            companyResponse: response
          });
        });
    }
  };

  renderModal = () => {

    if (this.state.salesModal === false && this.state.meetingsModal === false) {
      return (
        <CompanyModal
          appointment={this.state.appointment}
          company={this.state.company}
          toggle={this.toggle}
          toggleNested={this.toggleNested}
        />
      );
    } else {
      if (this.state.salesModal === true) {
        return (
          <div>
            <ModalHeader toggle={this.toggle}>
              <div className="back_arrow">
                <img
                  className="arrow"
                  src={arrow}
                  alt=""
                  onClick={this.handleSalesButton}
                />
                <span className="heading">
                  Order History {""}
                  {this.state.appointment_company_name}
                </span>
              </div>
            </ModalHeader>
            <ModalBody>
              <div>test</div>
            </ModalBody>
          </div>
        );
      } else {
        return (
          <div>
            <ModalHeader toggle={this.toggle}>
              <div className="back_arrow">
                <img
                  className="arrow"
                  src={arrow}
                  alt=""
                  onClick={this.handleMeetingsButton}
                />
                <span className="heading">
                  Past Appointments {""} {this.state.appointment_company_name}
                </span>
              </div>
            </ModalHeader>
            <ModalBody>
              <div>test meeting</div>
            </ModalBody>
          </div>
        );
      }
    }
  };

  render() {
    return (
      <div onClick={this.onbuttonclick}>
        <Modal
          className="dahlmodal"
          isOpen={this.state.modal}
          toggle={this.toggle}
        >
          {this.renderModal()}
        </Modal>
      </div>
    );
  }
}
