import React from "react";
import "../../App.css";
import Week from "./Week";
import DayNames from "./DayNames";
import moment from "moment";
import FontAwesomeIcon from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from "@fortawesome/fontawesome-free-solid";
import utils from "../../util/utils";

export default class Calendar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      month: moment(),
      selected: moment().startOf("day")
    };
  }

  componentWillReceiveProps(newprops) {
    if (newprops !== this.props) {
      this.setState({
        ...this.state,
        ...newprops
      });
    }
  }

  previous = () => {
    const { month } = this.state;
    this.setState({
      month: month.subtract(1, "month")
    });
    this.props.selectedDate(month.startOf('month'));
    this.props.selectedNotes(month.startOf('month'));
  }

  next = () => {
    const { month } = this.state;
    this.setState({
      month: month.add(1, "month")
    });
    this.props.selectedDate(month.startOf('month'));
    this.props.selectedNotes(month.startOf('month'));
  }

  select(day) {
    this.setState({
      selected: day.date,
      month: day.date.clone()
    });
    this.props.selectedDate(day.date);
    this.props.selectedNotes(day.date);
  }

  renderWeeks() {
    let weeks = [];
    let done = false;
    let date = this.state.month
      .clone()
      .startOf("month")
      .add("w" - 1)
      .day("Sunday");
    let count = 0;
    let monthIndex = date.month();

    const { selected, month } = this.state;

    while (!done) {
      weeks.push(
        <Week appointments={this.props.appointments}
          key={date}
          date={date.clone()}
          month={month}
          select={day => this.select(day)}
          selected={selected}
        />
      );

      date.add(1, "w");

      done = count++ > 2 && monthIndex !== date.month();
      monthIndex = date.month();
    }

    return weeks;
  }

  renderMonthLabel() {
    const { month } = this.state;

    return <span className="month-label">{month.format("MMMM YYYY")}</span>;
  }

  render() {
    return (
      <section className="calendar">
        <header className="header">
          <div className="month-display row">
            <span className="calendarHeaderPadding">
              <FontAwesomeIcon icon={faAngleLeft} onClick={this.previous} />
            </span>
            {this.renderMonthLabel()}
            <span className="calendarHeaderPadding">
              <FontAwesomeIcon icon={faAngleRight} onClick={this.next} />
            </span>
          </div>
          <DayNames />
        </header>
        {this.renderWeeks()}
      </section>
    );
  }
}
