import React from "react";
import plus from "../images/plus.png";
import AppointmentModal from "./AppointmentModal";
import moment from "moment";
import apicall from "../util/apicall";
import config from "../config";
import CompanyModal from "./CompanyModal";
import utils from "../util/utils";
import debounce from "lodash/debounce";
import AutoSuggestComponent from "./AutoSuggestComponent";
export default class Appointment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showmodal: false,
      modal: false,
      company: {},
      sort: "name",
      size: 20,
      from_: 0,
      name: "",
      allowNew: false,
      isLoading: false,
      multiple: false,
      options: [],
      index_: 0,
      appointment: [],
      isAutoSuggestCompleted: false,
      companyid: "",
      appointment: {}
    };
    this.state = {
      ...this.state,
      ...this.props
    };
  }

  toggle = index => {
    let todayAppointments = this.state.todayAppointments;
    todayAppointments[index]["showmodal"] = !todayAppointments[index][
      "showmodal"
    ];
    this.setState({
      todayAppointments: todayAppointments
    });
  };

  toggleModal = () => {
    this.setState({
      modal: !this.state.modal,
      autosuggestvalue: ""
    });
  };

  componentWillReceiveProps(newprops) {
    if (newprops !== this.props) {
      this.setState({
        ...this.state,
        ...newprops
      });
    }
  }

  getCompany = company_id => {
    if (company_id !== "" && typeof company_id !== undefined) {
      apicall.getCompanyById(company_id, this.props).then(response => {
        this.setState({
          company: response
        });
      });
    }
  };

  render() {
    let appointmentsList = this.state.todayAppointments.map(
      (appointment, index) => {
        return (
          <div className="data" key={"data-" + index}>
            <div className="eventInfo">
              <div className="numberCircle" key={"number-" + index}>
                {index + 1}
              </div>
              <div className="eventdata" onClick={() => this.toggle(index)}>
                <AppointmentModal
                  key={"appointment-modal" + index}
                  modal={appointment.showmodal}
                  index={index}
                  toggle={this.toggle}
                  appointment={appointment}
                />
                <p>{appointment.appointment_company_name}</p>
                <p className="">
                  {moment(appointment.starttime).format("hh:mm")}
                </p>
              </div>
            </div>
          </div>
        );
      }
    );
    return (
      <div className="eventlist">
        <div className="_date">{this.state.day}</div>
        <div className="eventform">
          <img className="plus" src={plus} alt="" />
          <AutoSuggestComponent
            getCompany={this.getCompany}
            toggleModal={this.toggleModal}
          />
        </div>
        <div className="eventList">
          <AppointmentModal
            modal={this.state.modal}
            toggle={this.toggleModal}
            company={this.state.company}
          />
          <span>{appointmentsList}</span>
        </div>
      </div>
    );
  }
}
