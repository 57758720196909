import React, { Component } from "react";
import FontAwesomeIcon from "@fortawesome/react-fontawesome";
import { faPlusSquare } from "@fortawesome/fontawesome-free-solid";
import AppointmentModal from "./AppointmentModal";
import moment from "moment";

export default class MapPointerComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      popoverIsOpen: "",
      showmodal: false,
    };
    this.state = {
      ...this.state,
      ...this.props
    };
  }

  componentWillReceiveProps(newprops) {
    if (newprops !== this.props) {
      this.setState({
        ...this.state,
        ...newprops
      });
    }
  }

  open = () => {
    this.setState({
      popoverIsOpen: "showpopover"
    });
  };
  openAppointmentModal = () => {
    this.setState({
      popoverIsOpen: ""
    });
  };
  toggle = () => {
    this.setState({
      showmodal: !this.state.showmodal
    });
  };
  render() {
    return (
      <div>
        <button
          data-rh="Default"
          id={"appointmentmap-" + this.state.index}
          className="badge"
          onClick={this.open}
        >
          {this.state.index + 1}
          <span className={"popover " + this.state.popoverIsOpen} onClick={this.toggle}>
            {this.state.appointments.companyName} -{" "}
            {moment(this.state.appointments.appointmentDate).format(
              "dddd, D MMM YYYY"
            )}
            <AppointmentModal
              key={"appointment-mapmodal" + this.state.index}
              modal={this.state.showmodal}
              toggle={this.toggle}
              appointment={this.state.appointments}
            />
            <span className="closepopover" onClick={this.openAppointmentModal}>
              <FontAwesomeIcon icon={faPlusSquare} />
            </span>
          </span>
        </button>
      </div>
    );
  }
}
