import config from "../config";
import moment from "moment";
import { withRouter } from "react-router";
var apicall = {
  _basicAuthCredentials: function (key, secret) {
    return "Basic " + btoa(key + ":" + secret);
  },

  getOauthToken: function (username, password, props) {
    var url = config.baseURL + "/token";

    var init = {
      method: "post",
      headers: {
        Authorization: this._basicAuthCredentials(
          config.clientID,
          config.secretKey
        ),
        Accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded"
      },
      body: `username=${username}&password=${password}&grant_type=password`
    };
    fetch(url, init)
      .then(resp => resp.json())
      .then(token => {
        var accessToken = token.access_token;
        if (typeof accessToken !== "undefined" && accessToken !== null) {
          config.localForage.setItem(config.accessToken, accessToken);
          config.localForage.setItem("tokenDate", new Date());
          config.localForage.setItem("username", username);
          props.history.push("/homepage");
        }
      })
      .catch(function (error) {
        console.log("A error Occured while Fetching Token");
      });
  },

  getappointments: async function (_from, days, props) {
    var url = config.baseURL + "/appointments";
    var token = null;
    await config.localForage
      .getItem(config.accessToken)
      .then(function (value) {
        if (typeof value !== "undefined" && value !== null) {
          console.log("token retrieved inside appointments");
          token = value;
        }
      })
      .catch(function (err) {
        console.log(err);
      });
    url = url + "?from=" + _from + "&to=" + days;
    var _data = {
      headers: {
        Authorization: "Bearer " + token
      }
    };
    var responseData = null;
    await fetch(url, _data)
      .then(resp => resp.json())
      .then(response => {
        var monthYear = moment()
          .add(_from, "days")
          .format("MMM YYYY");
        config.localForage.setItem(monthYear, response);
        responseData = response;
      })
      .catch(function (error) {
        props.history.push("/");
        console.log("error occured while fetching appointment");
      });
    return responseData;
  },

  getCompany: async function (sort, size, from_, name, props) {
    var token = null;
    var companyResponse = null;
    await config.localForage
      .getItem(config.accessToken)
      .then(function (value) {
        if (typeof value !== "undefined" && value !== null) {
          token = value;
        }
      })
      .catch(function (err) {
        console.log(err);
      });
    var generatedurl =
      config.baseURL +
      "/companies?sort=" +
      sort +
      "&size=" +
      size +
      "&from=" +
      from_ +
      "&name=" +
      name;
    await fetch(generatedurl, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token
      }
    })
      .then(res => {
        return res.json();
      })
      .then(function (response) {
        companyResponse = response;
      })
      .catch(function (error) {
        props.history.push("/");
        console.log("error occured while fetching token");
      });
    return companyResponse;
  },

  getappointmentNotes: async function (_from, days, props) {
    var token = null;
    var notesResponse = null;
    await config.localForage
      .getItem(config.accessToken)
      .then(function (value) {
        if (typeof value !== "undefined" && value !== null) {
          console.log("token retrieved inside appointments");
          token = value;
        }
      })
      .catch(function (err) {
        console.log(err);
      });
    var generatedurl =
      config.baseURL + "/notes?from=" + _from + "&days=" + days;
    await fetch(generatedurl, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token
      }
    })
      .then(res => {
        return res.json();
      })
      .then(response => {
        var monthYear = moment()
          .add(_from, "days")
          .format("MMM YYYY");
        config.localForage.setItem("stats " + monthYear, response);
        notesResponse = response;
      })
      .catch(function (error) {
        props.history.push("/");
        console.log("error occured while fetching token");
      });
    return notesResponse;
  },

  searchCompanyInMap: async function (
    bottom_right,
    top_left,
    location,
    name,
    props
  ) {
    var token = null;
    var companyResponse = null;
    await config.localForage
      .getItem(config.accessToken)
      .then(function (value) {
        if (typeof value !== "undefined" && value !== null) {
          token = value;
        }
      })
      .catch(function (err) {
        console.log(err);
      });

    var generatedurl =
      config.baseURL +
      "/companies?bbox_bottom_right=" +
      bottom_right +
      "&bbox_top_left=" +
      top_left +
      "&from=0" +
      "&name=" +
      name +
      "&location=" +
      location +
      "&sensor=true" +
      "&size=100" +
      "&sort=distance";

    await fetch(generatedurl, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token
      }
    })
      .then(res => {
        return res.json();
      })
      .then(function (response) {
        companyResponse = response;
      })
      .catch(function (error) {
        props.history.push("/");
        console.log("error occured while fetching token");
      });
    return companyResponse;
  },

  getActionPoints: async function (props) {
    var token = null;
    var actionResponse = null;
    await config.localForage
      .getItem(config.accessToken)
      .then(function (value) {
        if (typeof value !== "undefined" && value !== null) {
          token = value;
        }
      })
      .catch(function (err) {
        console.log(err);
      });
    var generatedurl =
      config.baseURL + "/actions";
    await fetch(generatedurl, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token
      }
    })
      .then(res => {
        return res.json();
      })
      .then(response => {
        config.localForage.setItem(config.actionData, response);
        actionResponse = response;
      })
      .catch(function (error) {
        props.history.push("/");
        console.log("error occured while fetching token");
      });
    return actionResponse;
  },

  saveAppointments: async function (
    companyMap,
    company_id,
    starttime,
    actionResult,
    props) {
    var token = null;
    var saveAppointmentsResponse = null;
    await config.localForage
      .getItem(config.accessToken)
      .then(function (value) {
        if (typeof value !== "undefined" && value !== null) {
          token = value;
        }
      })
      .catch(function (err) {
        console.log(err);
      });
    var generatedurl =
      config.baseURL + "/appointments";
    let actionMap = {};
    actionMap["actions"] = actionResult;
    if ("company_name" in companyMap) {
      actionMap["appointment_company_name"] = companyMap["company_name"];
    }
    if ("notes" in companyMap) {
      actionMap["notes"] = companyMap["notes"];
    }
    actionMap["company_id"] = company_id;
    actionMap["duration"] = 60;
    actionMap["order_value"] = 0;
    actionMap["starttime"] = "" + starttime;
    console.log(JSON.stringify(actionMap));
    await fetch(generatedurl, {
      method: "POST",
      body: JSON.stringify(actionMap),
      headers: {
        'Authorization': "Bearer " + token,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    })
      .then(response => {
        saveAppointmentsResponse = response;
      })
      .catch(function (error) {
        props.history.push("/");
        console.log("error occured while fetching token");
      });
    return saveAppointmentsResponse;
  },

  updateCompanyDetails: async function (
    company_id,
    companyUpdateMap,
    props
  ) {
    var token = null;
    var UpdatedCompanyResponse = null;
    await config.localForage
      .getItem(config.accessToken)
      .then(function (value) {
        if (typeof value !== "undefined" && value !== null) {
          token = value;
        }
      })
      .catch(function (err) {
        console.log(err);
      });

    var generatedurl =
      config.baseURL + "/companies/" + company_id;
    await fetch(generatedurl, {
      method: "PUT",
      body: JSON.stringify(companyUpdateMap),
      headers: {
        Authorization: "Bearer " + token,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    })
      .then(function (response) {
        UpdatedCompanyResponse = response;
      })
      .catch(function (error) {
        props.history.push("/");
        console.log("error occured while fetching token");
      });
    return UpdatedCompanyResponse;
  },

  getCompanyById: async function (company_id, props) {
    var token = null;
    var companyResponse = null;
    await config.localForage
      .getItem(config.accessToken)
      .then(function (value) {
        if (typeof value !== "undefined" && value !== null) {
          token = value;
        }
      })
      .catch(function (err) {
        console.log(err);
      });
    var generatedurl =
      config.baseURL + "/companies/" + company_id;
    await fetch(generatedurl, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token
      }
    })
      .then(res => {
        return res.json();
      })
      .then(response => {
        companyResponse = response;
      })
      .catch(function (error) {
        props.history.push("/");
        console.log("error occured while fetching token");
      });
    return companyResponse;
  },

  logout: function () {
    var req = indexedDB.deleteDatabase(config.databasename);
    req.onsuccess = function () {
      console.log("Deleted database successfully");
    };
  }
};

export default withRouter(apicall);
