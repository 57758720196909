import React from "react";
import { Navbar, Nav, NavItem, NavbarBrand } from "reactstrap";
import logo from "../images/DAHL_Logo.png";
import power from "../images/power.png";
import config from "../config";
import moment from "moment";
export default class NavbarDAHL extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      monthAppointments: [],
      actions: [],
      ...this.state,
      ...this.props
    };
  }
  componentWillReceiveProps(newprops) {
    if (newprops !== this.props) {
      this.setState({
        ...this.state,
        ...newprops
      });
    }
  }

  render() {
    let todayPoints = 0;
    let todayAppointments = this.state.todayAppointments;
    if (todayAppointments !== null && todayAppointments !== undefined) {
      for (var i = 0; i < todayAppointments.length; i++) {
        todayPoints = todayPoints + todayAppointments[i].points;
      }
    }
    var month = this.state.month;
    config.localForage
      .getItem(month)
      .then(value => {
        this.setState({
          monthAppointments: value
        });
      })
      .catch(function (err) {
        console.log(err);
      });
    let monthAppointments = this.state.monthAppointments;
    let monthPoints = 0;
    if (monthAppointments !== null && monthAppointments !== undefined) {
      for (var i = 0; i < monthAppointments.length; i++) {
        monthPoints = monthPoints + monthAppointments[i].points;
      }
    }

    return (
      <div>
        <Navbar className="top-bar" light expand="md">
          <NavbarBrand className="power">
            <img src={power} alt="" />
          </NavbarBrand>
          <NavbarBrand className="navimage">
            <img className="logoimage" src={logo} alt="" />
          </NavbarBrand>
          <Nav className="ml-auto" navbar>
            <NavItem>
              <div>
                <p>Saljara : {this.state.username}</p>
                <p>Dagens poang : {todayPoints}</p>
              </div>
            </NavItem>
          </Nav>
          <Nav className="ml-auto" navbar>
            <NavItem>
              <div>
                <p>{this.state.date.toLocaleString()}</p>
                <p>Manadens poang : {monthPoints}</p>
              </div>
            </NavItem>
          </Nav>
        </Navbar>
      </div>
    );
  }
}
