import React from "react";
import { Route, Redirect } from "react-router-dom";
import config from "../config";

const fakeAuth = {
  isAuthenticated: false,
  async checkAuthenticate() {
    await config.localForage.getItem(config.accessToken).then(value => {
      this.isAuthenticated = false;
      if (typeof value !== "undefined" && value !== null) {
        this.isAuthenticated = true;
      }
    });
    return this.isAuthenticated;
  },
  async authStatus() {
    var status = await this.checkAuthenticate().then(status => {
      return status;
    });
    return status;
  }
};

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      fakeAuth.authStatus() ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: "/",
            state: { from: props.location }
          }}
        />
      )
    }
  />
);
export default PrivateRoute;
