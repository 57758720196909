import React, { Component } from "react";
import "../App.css";
import DatePickerComponent from "./DatePickerComponent";
import config from "../config";
import apicall from "../util/apicall";
import moment from "moment";
import arrow from "../images/arrow.png";
import Utils from "../util/utils";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Form,
  FormGroup,
  Label,
  Col
} from "reactstrap";

export default class CompanyModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      actionData: [],
      appointment: null,
      company: {},
      companyDetails: {},
      actionIDArray: []
    };
    this.state = {
      ...this.state,
      ...this.props
    };
  }

  componentWillReceiveProps(newprops) {
    if (newprops !== this.props) {
      this.setState({
        ...this.state,
        ...newprops
      });
      this.processCompanyInfo();
    }
  }

  componentWillMount() {
    config.localForage
      .getItem(config.actionData)
      .then(value => {
        let actionsMapArray = value;
        let actionIDArray = [];
        for (var i = 0; i < actionsMapArray.length; i++) {
          actionIDArray.push(actionsMapArray[i].id);
        }
        this.setState({
          actionData: this.renderActions(actionsMapArray),
          actionIDArray: actionIDArray
        });
      })
      .catch(function(err) {
        console.log(err);
      });
  }

  handleInputChange = event => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
  };

  renderActions(actionsMapArray) {
    return actionsMapArray.map((action, index) => {
      return (
        <div key={"action-" + index}>
          <div className="divleft">
            <label className="control control--checkbox">
              {action.name}
              <input
                id={action.id}
                name={action.id}
                type="checkbox"
                checked={this.state[action.id]}
                onChange={this.handleInputChange}
              />
              <div className="control__indicator" />
              <div className={"hide" + (this.state[action.id] ? "show" : "")}>
                <div className="points">{action.points}p</div>
              </div>
            </label>
          </div>
        </div>
      );
    });
  }

  saveAppointments = () => {
    var actionArray = this.state.actionIDArray;
    var actionResultArray = [];
    for (var i = 0; i < actionArray.length; i++) {
      let actionId = actionArray[i];
      if (this.state[actionId] === true) {
        actionResultArray.push(actionArray[i]);
      }
    }

    var companyArray = ["company_name", "notes"];
    var companyMap = {};
    for (var i = 0; i < companyArray.length; i++) {
      let stateName = companyArray[i];
      if (this.state[stateName] !== "" && this.state[stateName] !== undefined) {
        companyMap[stateName] = this.state[stateName];
      }
    }
    apicall
      .saveAppointments(
        companyMap,
        this.state.appointment_company_id,
        this.state.starttime_unix,
        actionResultArray,
        this.props
      )
      .then(response => {
        this.setState({
          saveResponse: response
        });
      });
    this.props.toggle();
  };

  updateCompany = () => {
    var companyDetailsArray = [
      "company_name",
      "street_address",
      "phone",
      "zip",
      "reference",
      "city"
    ];
    var companyUpdateMap = {};
    for (var i = 0; i < companyDetailsArray.length; i++) {
      let stateName = companyDetailsArray[i];
      if (this.state[stateName] !== "" && this.state[stateName] !== undefined) {
        companyUpdateMap[stateName] = this.state[stateName];
      }
    }
    apicall
      .updateCompanyDetails(
        this.state.appointment_company_id,
        companyUpdateMap,
        this.props
      )
      .then(response => {
        this.setState({
          updateCompanyResponse: response
        });
      });
    this.toggle();
  };

  toggleNestedDateModal = () => {
    this.setState({
      nestedDateModal: !this.state.nestedDateModal
    });
  };

  processCompanyInfo = () => {
    var appointmentAvailable = Utils.isEmpty(this.state.appointment);
    var companyDetails = {};
    if (!appointmentAvailable) {
      companyDetails[
        "companyName"
      ] = this.state.appointment.appointment_company_name;
      companyDetails["companyStartTime"] = this.state.appointment.starttime;
      companyDetails[
        "companyAddress"
      ] = this.state.appointment.appointment_street_addr;
      companyDetails["companyZip"] = this.state.appointment.appointment_zip;
      companyDetails["companyCity"] = this.state.appointment.appointment_city;
      companyDetails["companyPhone"] = this.state.appointment.appointment_phone;
      companyDetails[
        "companyReference"
      ] = this.state.appointment.appointment_reference;
      companyDetails[
        "companyID"
      ] = this.state.appointment.appointment_company_id;
      companyDetails["companyNotes"] = this.state.appointment.notes;
      companyDetails["companyDay"] = this.state.appointment.appointment_day;
    } else if (!Utils.isEmpty(this.state.company)) {
      companyDetails["companyName"] = this.state.company.company_name;
      companyDetails["companyStartTime"] = new moment();
      companyDetails["companyAddress"] = this.state.company.street_address;
      companyDetails["companyZip"] = this.state.company.zip;
      companyDetails["companyCity"] = this.state.company.city;
      companyDetails["companyPhone"] = this.state.company.phone;
      companyDetails["companyReference"] = this.state.company.reference;
      companyDetails["companyID"] = this.state.company.company_id;
      companyDetails["companyNotes"] = "";
      companyDetails["companyDay"] = new moment();
    }
    this.setState({
      companyDetails: companyDetails
    });
  };

  render() {
    return (
      <div>
        <ModalHeader toggle={this.props.toggle}>
          <div>
            <Button className="cancel" onClick={this.props.toggle}>
              AVBRYT
            </Button>
            <span className="heading">
              {this.state.companyDetails.companyName}
            </span>
            <Button className="spara" onClick={this.saveAppointments}>
              SPARA
            </Button>
          </div>
        </ModalHeader>
        <ModalBody>
          <div className="modal_content">
            <Modal
              className="nested_modal_DatePicker"
              isOpen={this.state.nestedDateModal}
              toggle={this.toggleNestedDateModal}
              onClosed={this.state.closeAll ? this.toggle : undefined}
            >
              <ModalBody>
                <div>
                  <DatePickerComponent
                    starttime={this.state.companyDetails.companyStartTime}
                  />
                </div>
              </ModalBody>
              <ModalFooter>
                <Button
                  className="dahl_green"
                  onClick={this.state.toggleNestedDateModal}
                >
                  close
                </Button>{" "}
              </ModalFooter>
            </Modal>
            <Modal
              className="nested_modal_kundkort"
              isOpen={this.state.nestedModal}
              toggle={this.state.toggleNested}
              onClosed={this.state.closeAll ? this.props.toggle : undefined}
            >
              <ModalBody>
                <div>
                  <Form className="kundkortForm">
                    <FormGroup>
                      <Input
                        type="text"
                        name="company_name"
                        id="company_name"
                        placeholder="Namn pä företaget"
                        defaultValue={this.state.companyDetails.companyName}
                        onChange={this.handleInputChange}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Input
                        type="text"
                        name="street_address"
                        id="streetAddress"
                        placeholder="Gatuadress"
                        defaultValue={this.state.companyDetails.companyAddress}
                        onChange={this.handleInputChange}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Input
                        type="text"
                        name="postnummer"
                        id="postnummer"
                        placeholder="postnummer"
                        defaultValue={this.state.companyDetails.companyZip}
                        onChange={this.handleInputChange}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Input
                        type="text"
                        name="city"
                        id="city"
                        placeholder="ort"
                        defaultValue={this.state.companyDetails.companyCity}
                        onChange={this.handleInputChange}
                      />
                    </FormGroup>
                    <FormGroup row>
                      <Label for="contact" sm={2}>
                        Kontakt
                      </Label>
                      <Col sm={5}>
                        <Input
                          type="text"
                          name="phone"
                          id="phone"
                          placeholder="Telefon"
                          defaultValue={this.state.companyDetails.companyPhone}
                          onChange={this.handleInputChange}
                        />
                      </Col>
                      <Col sm={5}>
                        <Input
                          type="text"
                          name="epost"
                          id="epost"
                          placeholder="E-post"
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label for="Refer" sm={2}>
                        Referens
                      </Label>
                      <Col sm={10}>
                        <Input
                          type="text"
                          name="reference"
                          id="reference"
                          placeholder="Namn"
                          defaultValue={
                            this.state.companyDetails.companyReference
                          }
                          onChange={this.handleInputChange}
                        />
                      </Col>
                    </FormGroup>
                    <div className="kundid">
                      <span>kund-id</span>
                      <span>{this.state.companyDetails.companyID}</span>
                    </div>
                  </Form>
                  <div className="KundkortButton">
                    <Button className="sparaBlue" onClick={this.updateCompany}>
                      spara
                    </Button>
                    <Button
                      className="cancelBlue"
                      onClick={this.state.toggleNested}
                    >
                      avbryt
                    </Button>{" "}
                  </div>
                </div>
              </ModalBody>
            </Modal>
            <div className="companydetails">
              <div className="_data">
                <div className="displaydata">
                  <p>{this.state.companyDetails.companyName}</p>
                  <p>{this.state.companyDetails.companyAddress}</p>
                  <p>{this.state.companyDetails.companyPhone}</p>
                </div>
                <div className="editdata">
                  <Button
                    className="dahl_green"
                    onClick={this.state.toggleNested}
                  >
                    KUNDKORT
                  </Button>
                </div>
              </div>
              <div className="register">Registrera säljaktiviteter</div>
              <div className="reg_date">
                <span className="date_left">
                  {moment(this.state.companyDetails.companyDay).format(
                    "dddd, D MMM YYYY"
                  )}
                </span>
                <span
                  className="date_right"
                  onClick={this.toggleNestedDateModal}
                >
                  {moment(this.state.companyDetails.companyStartTime).format(
                    "dddd, D MMM YYYY"
                  )}{" "}
                  {moment(this.state.companyDetails.companyStartTime).format(
                    "hh:mm"
                  )}
                </span>
              </div>
              <div className="checkboxlist">{this.state.actionData}</div>
              <div className="textnotes">
                <Input
                  type="textarea"
                  name="notes"
                  id="notes"
                  placeholder="Notes"
                  onChange={this.handleInputChange}
                  defaultValue={this.state.companyDetails.companyNotes}
                />
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="footertext">Se tidigare aktiviteter hos kund</div>
          <Button className="dahl_green" onClick={this.handleSalesButton}>
            FÖRSÅLJNING
          </Button>
          <Button className="dahl_green" onClick={this.handleMeetingsButton}>
            MÖTEN
          </Button>
        </ModalFooter>
      </div>
    );
  }
}
