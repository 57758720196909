import React, { Component } from "react";
import "../App.css";
import apicall from "../util/apicall";
import utils from "../util/utils";
import MapComponent from "./MapComponent";
import NavbarDAHL from "./NavbarDAHL";
import Appointment from "./Appointment";
import CalendarDAHL from "./CalendarDAHL";
import moment from "moment";
import config from "../config";
import { BubbleLoader } from "react-css-loaders";

export default class HomePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: utils.timeNow(),
      username: "",
      day: moment().format("MMM Do YYYY"),
      _from: -30,
      days: 40,
      appointments: [],
      todayAppointments: [],
      sort: "name",
      size: 20,
      from_: 0,
      allCompanies: [],
      loadershow: "",
      todayNotes: "",
      actions: [],
      month:moment().format("MMMM YYYY")
    };
  }

  handlelogout = () => {
    apicall.logout();
  };

  componentDidMount() {
    this.timerID = setInterval(() => this.tick(), 1000);
    this.fetchAppointments();
    this.fetchActions();
  }

  componentWillUnmount() {
    clearInterval(this.timerID);
  }

  fetchActions = () => {
    apicall.getActionPoints()
      .then(response => {
        this.setState({
          actions: response
        });
      });
  };

  fetchAppointments = () => {
    var choosenDate = moment(this.state.day, "MMM Do YYYY");
    var monthEndDate = choosenDate.clone().endOf("month");
    var todayDate = moment();
    var diffInDays = choosenDate.diff(monthEndDate, "days");
    var diffFromToday = todayDate.diff(choosenDate, "days");
    let fromDays = diffInDays;
    diffInDays = diffInDays / 28;
    if (diffInDays < 0) {
      diffInDays = diffInDays * -1;
    }
    if (diffFromToday === 0 && moment(choosenDate).isSame(new Date(), 'month')) {
      diffFromToday = -60;
      diffInDays = 1;
    }
    if (diffFromToday > 0) {
      diffFromToday = diffFromToday * -1;
    }
    this.setState(
      {
        _from: diffFromToday
      },
      () => {
        if (diffInDays >= 1) {
          this.setState({
            loadershow: "loadershow"
          });
          apicall
            .getappointments(this.state._from, this.state.days, this.props)
            .then(response => {
              this.setState({
                appointments: response,
                loadershow: ""
              });
              this.calculateTodayAppointment();
            });
        }
      }
    );
  };

  tick() {
    this.setState({
      date: utils.timeNow()
    });
  }
  calculateTodayAppointment = () => {
    let todayAppointment = [];
    var appointments = this.state.appointments;
    if (typeof appointments !== "undefined" && appointments != null) {
      for (var i = 0; i < appointments.length; i++) {
        var appointmentDate = appointments[i].starttime;
        var appointmentDateFormat = moment(appointmentDate).format(
          "MMM Do YYYY"
        );
        if (appointmentDateFormat === this.state.day) {
          appointments[i]["showmodal"] = false;
          todayAppointment.push(appointments[i]);
        }
      }
    }
    this.setState({
      todayAppointments: todayAppointment
    });
  };



  selectedDate = selectDate => {
    var previousDate = moment(this.state.day);
    this.setState(
      {
        month:selectDate.format("MMM YYYY"),
        day: selectDate.format("MMM Do YYYY")
      },
      () => {
        this.fetchAppointments();
        this.calculateTodayAppointment();
      }
    );
  };

  render() {
    config.localForage
      .getItem("username")
      .then(value => {
        this.setState({
          username: value
        });
      })
      .catch(function (err) {
        console.log(err);
      });

    return (
      <div>
        <NavbarDAHL
          date={this.state.date}
          username={this.state.username}
          todayAppointments={this.state.todayAppointments} 
          day={this.state.day}
          month={this.state.month}/>
        <Appointment
          date={this.state.date}
          day={this.state.day}
          appointments={this.state.appointments}
          todayAppointments={this.state.todayAppointments}
        />
        <div className={"loader " + this.state.loadershow}>
          <BubbleLoader color="#28a745" size="8" />
        </div>
        <CalendarDAHL
          selectedDate={this.selectedDate}
          appointments={this.state.appointments}
          fetchAppointments={this.fetchAppointments}
          day={this.state.day}
        />
        <div className="_googlemap">
          <MapComponent appointments={this.state.todayAppointments} />
        </div>
      </div>
    );
  }
}
