import React from "react";
import Autosuggest from "react-autosuggest";
import apicall from "../util/apicall";
import debounce from "lodash/debounce";
export default class AutoSuggestComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      companyArray: [],
      autosuggestvalue: ""
    };
    this.state = {
      ...this.state,
      ...this.props
    };
  }
  componentWillReceiveProps(newprops) {
    if (newprops !== this.props) {
      this.setState({
        ...this.state,
        ...newprops
      });
    }
  }

  fetchCompany = name => {
    if (name == null || name.value.length <= 2 || !isNaN(name.value)) {
      return;
    }
    this.setState({
      name: name.value
    });
    apicall
      .getCompany(
        this.state.sort,
        this.state.size,
        this.state.from_,
        name.value
      )
      .then(resp => {
        if (typeof resp !== "undefined" && resp != null) {
          let companySearchDetails = [];
          var companies = resp.hits;
          for (var i = 0; i < companies.length; i++) {
            var companyObj = {};
            companyObj["name"] = companies[i].company_name;
            companyObj["id"] = companies[i].company_id;
            if (typeof companies[i].city !== "undefined") {
              companyObj["city"] = companies[i].city;
            } else {
              companyObj["city"] = "";
            }
            companyObj["district"] = companies[i].district;
            companySearchDetails.push(companyObj);
          }
          this.setState({
            companyArray: companySearchDetails
          });        
        }
      });
  };

  renderSuggestion = suggestion => (
    <div>
      {suggestion.name} -{suggestion.city} -{suggestion.district}
    </div>
  );

  setAutcomplete = (event, { suggestion, suggestionValue }) => {
    var company_id = suggestionValue;
    console.log(
      "Company ID :" +
        company_id +
        " Auto Suggest Completed " +
        this.state.isAutoSuggestCompleted
    );
    this.setState({
      isAutoSuggestCompleted: !this.state.isAutoSuggestCompleted
    });
    if (typeof this.props.getCompany !== "undefined") {
      this.props.getCompany(company_id);
    }
    if (typeof this.state.toggleModal !== "undefined") {
        this.state.toggleModal();
    }
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  onSuggestionsClearRequested = () => {
    this.setState({
      companyArray: []
    });
  };

  onChange = (event, { newValue }) => {
    this.setState({
      autosuggestvalue: newValue
    });
  };

  render() {
    // Autosuggest will pass through all these props to the input.
    const inputProps = {
      placeholder: "Search company to add appointment",
      value: this.state.autosuggestvalue,
      onChange: this.onChange
    };
    return (
      <Autosuggest
        className="search"
        suggestions={this.state.companyArray}
        onSuggestionsFetchRequested={debounce(this.fetchCompany, 200)}
        getSuggestionValue={suggestion => suggestion.id}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        renderSuggestion={this.renderSuggestion}
        highlightFirstSuggestion={true}
        focusInputOnSuggestionClick={false}
        inputProps={inputProps}
        onSuggestionSelected={this.setAutcomplete}
      />
    );
  }
}
