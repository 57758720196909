import localforage from "localforage";
const config = {
  secretKey : "AYYHD7MQ53SU2T4V4LUPPNTT53HWU5E3",
  clientID : "5VETYN7K7RMTT6UQDFLMGFDII2XH4VXH",
  baseURL : "https://dahlcrm.cortexcraft.com",
  accessToken : "accessToken",
  databasename:"dahlmedical",
  googleapiKey : "AIzaSyBaDKkbPdbzH3SXEf1Ruan5NdgovbgTMCg",
  localForage :  localforage.createInstance({
    name: "dahlmedical"
  }),
  actionData : "actionData",
}

export default config;