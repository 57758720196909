import React from "react";
import Calendar from "./Calendar/Calendar";
import car from "../images/car-front.png";
import refresh from "../images/refresh.png";
import apicall from "../util/apicall";
import moment from "moment";

export default class CalendarDAHL extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      _from: -30,
      day: moment().format("MMM Do YYYY"),
      days: 40,
      notesDescription: "",
      mileageStart: "",
      mileageEnd: "",
      notes: []
    };
    this.state = {
      ...this.state,
      ...props
    };
  }
  componentDidMount() {
    this.fetchNotes();
  }
  componentWillReceiveProps(newprops) {
    if (newprops !== this.props) {
      this.setState({
        ...this.state,
        ...newprops
      });
    }
  }

  handleChange = event => {
    const target = event.target;
    const name = target.name;
    const value = target.value;
    this.setState({
      [name]: value
    });
  };

  fetchNotes = () => {
    var choosenDate = moment(this.state.day, "MMM Do YYYY");
    var monthEndDate = choosenDate.clone().endOf("month");
    var todayDate = moment();
    var diffInDays = choosenDate.diff(monthEndDate, "days");
    var diffFromToday = todayDate.diff(choosenDate, "days");
    let fromDays = diffInDays;
    diffInDays = diffInDays / 28;
    if (diffInDays < 0) {
      diffInDays = diffInDays * -1;
    }
    if (
      diffFromToday === 0 &&
      moment(choosenDate).isSame(new Date(), "month")
    ) {
      diffFromToday = -60;
      diffInDays = 1;
    }
    if (diffFromToday > 0) {
      diffFromToday = diffFromToday * -1;
    }
    this.setState(
      {
        _from: diffFromToday
      },
      () => {
        if (diffInDays >= 1) {
          apicall
            .getappointmentNotes(this.state._from, this.state.days, this.props)
            .then(resp => {
              let todayNote = "";
              var notes = resp;
              this.setState(
                {
                  notes: notes
                },
                () => {
                  this.calculateTodayNotes();
                }
              );
            });
        } else {
          this.calculateTodayNotes();
        }
      }
    );
  };
  calculateTodayNotes = () => {
    let todayNotes = {
      notesDescription: "",
      mileageStart: "",
      mileageEnd: ""
    };
    let notes = this.state.notes;
    if (typeof notes != "undefined" && notes != null) {
      for (var i = 0; i < notes.length; i++) {
        var notesDate = notes[i].date;
        var notesDateFormat = moment(notesDate).format("MMM Do YYYY");
        if (notesDateFormat === this.state.day) {
          todayNotes.notesDescription = notes[i].text;
          todayNotes.mileageStart = notes[i].mileage_start;
          todayNotes.mileageEnd = notes[i].mileage_end;
        }
      }
    }
    this.setState({
      notesDescription: todayNotes.notesDescription,
      mileageStart: todayNotes.mileageStart,
      mileageEnd: todayNotes.mileageEnd
    });
  };

  selectedNotes = selectDate => {
    var previousDate = moment(this.state.day);
    this.setState(
      {
        day: selectDate.format("MMM Do YYYY")
      },
      () => {
        console.log("Day is :" + this.state.day);
        this.fetchNotes();
      }
    );
  };

  render() {
    return (
      <div className="_calender">
        <Calendar
          appointments={this.state.appointments}
          selectedDate={this.state.selectedDate}
          selectedNotes={this.selectedNotes}
        />
        <div className="footer">
          <section className="box">
            <section className="paper" id="note-detail">
              <textarea
                type="text"
                name="notesDescription"
                placeholder="Type your stats here"
                onChange={this.handleChange}
                value={this.state.notesDescription}
              />
            </section>
          </section>
          <div className="seachdata">
            <img className="car" src={car} alt="" />
            <input
              name="mileageStart"
              className="searchstart"
              type="text"
              placeholder="Start"
              onChange={this.handleChange}
              value={this.state.mileageStart}
            />
            <input
              name="mileageEnd"
              className="searchend"
              type="text"
              placeholder="End"
              onChange={this.handleChange}
              value={this.state.mileageEnd}
            />
            <img className="refresh" src={refresh} alt="" />
          </div>
        </div>
      </div>
    );
  }
}
