import React, { Component } from "react";
import config from "../config";
import { geolocated } from "react-geolocated";
import GoogleMapReact from "google-map-react";
import MapPointerComponent from "./MapPointerComponent";
import utils from "../util/utils";
import apicall from "../util/apicall";
import memoizeOne from 'memoize-one';
import deepEqual from 'lodash/isEqual';
import AutoSuggestComponent from "./AutoSuggestComponent";
class MapComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      center: [59.5282, 18.6435],
      zoom: 3,
      modal: false,
      company: {},
    };
    this.state = {
      ...this.state,
      ...this.props
    };
  }

  componentWillReceiveProps(newprops) {
    if (!utils.isEquivalent(newprops, this.props)) {
      this.setState({
        ...this.state,
        ...newprops
      });

    }
  }

  updateBoundsOnCalendarChange = (appointments) => {
    var map = this.state.map;
    var maps = this.state.maps;
    if (typeof maps !== "undefined" && maps !== null) {
      const bounds = this.calculateBounds(maps)
      map.fitBounds(bounds);
      var mylat = map.getCenter().lat();
      var mylng = map.getCenter().lng();
      let centerMap = [];
      centerMap.push(mylat);
      centerMap.push(mylng);
      this.setState({
        center: centerMap
      })
      console.log(bounds);
    }
  }
  _onChange = ({ center, zoom }) => {
    this.setState({
      center: center,
      zoom: zoom
    });
  };

  getCompany = company_id => {
    if (company_id !== "" && typeof company_id !== undefined) {
      apicall.getCompanyById(company_id, this.props).then(response => {
        this.setState({
          company: response
        });
      });
    }
  };

  toggleModal = () => {
    this.setState({
      modal: !this.state.modal,
      autosuggestvalue: ""
    });
  };

  renderMarkers(appointmentsMapArray) {
    return appointmentsMapArray.map((appointments, index) => {
      return (
        <MapPointerComponent
          key={"map-" + index}
          appointments={appointments}
          index={index}
          lat={appointments.latitude}
          lng={appointments.longitude}
        />
      );
    });
  }

  apiIsLoaded = (map, maps) => {
    if (map) {
      const bounds = this.calculateBounds(maps)
      map.fitBounds(bounds);
      this.setState({
        map: map,
        maps: maps
      });
      // Bind the resize listener
      this.bindResizeListener(map, maps, bounds);
    }
  };

  calculateBounds = (maps) => {
    const bounds = new maps.LatLngBounds();
    let appointments = this.state.appointments;
    if (typeof appointments !== "undefined" && appointments !== null) {
      appointments.forEach((appointment, index) => {
        if (
          typeof appointment.appointment_location !== "undefined" &&
          appointment.appointment_location.length > 1
        ) {
          bounds.extend(new maps.LatLng(
            appointment.appointment_location[1],
            appointment.appointment_location[0]
          ));
        }
      });
    }
    return bounds;
  }
  updateBoundsOnCalendarChangeInMemory = memoizeOne(this.updateBoundsOnCalendarChange);

  // Re-center map when resizing the window
  bindResizeListener = (map, maps, bounds) => {
    maps.event.addDomListenerOnce(map, 'idle', () => {
      maps.event.addDomListener(window, 'resize', () => {
        map.fitBounds(bounds);
      });
    });
  };

  fetchAllCompaniesInView = () => {
    let map = this.state.map;
    let maps = this.state.maps;
    const bounds = map.getBounds();
    console.log(maps);
    let aNorth = map.getBounds().getNorthEast().lat();
    let aEast = map.getBounds().getNorthEast().lng();
    let aSouth = map.getBounds().getSouthWest().lat();
    let aWest = map.getBounds().getSouthWest().lng();
    var bottomRight = aEast + "," + aNorth;
    var topleft = aWest + "," + aSouth;
    var centerLocation = map.getCenter().lng() + "," + map.getCenter().lat();
    apicall.searchCompanyInMap(
      topleft,
      bottomRight,
      centerLocation,
      "",
      this.props
    );
  };

  render() {
    this.updateBoundsOnCalendarChangeInMemory(this.state.appointments, deepEqual);
    let appointments = this.state.appointments;
    let appointmentsMapArray = [];
    for (var i = 0; i < appointments.length; i++) {
      let appointmentsMap = {};
      if (
        typeof appointments[i].appointment_location !== "undefined" &&
        appointments[i].appointment_location.length > 1
      ) {
        appointmentsMap.latitude = appointments[i].appointment_location[1];
        appointmentsMap.longitude = appointments[i].appointment_location[0];
        appointmentsMap.id = appointments[i].id;
        appointmentsMap.companyName = appointments[i].appointment_company_name;
        appointmentsMap.street_address = appointments[i].street_address;
        appointmentsMap.phone = appointments[i].phone;
        appointmentsMap.starttime = appointments[i].starttime;
        appointmentsMap.zip = appointments[i].zip;
        appointmentsMap.appointment_company_id = appointments[i].company_id;
        appointmentsMap.starttime_unix = appointments[i].starttime_unix;
        appointmentsMap.appointment_notes = appointments[i].notes;
        appointmentsMap.city = appointments[i].city;
        appointmentsMap.reference = appointments[i].reference;
        appointmentsMap.start = appointments[i].starttime;
        appointmentsMap.appointmentDate = appointments[i].starttime;
        appointmentsMapArray.push(appointmentsMap);
      }
    }
    let markers = this.renderMarkers(appointmentsMapArray);
    return (
      <div style={{ height: "100vh", width: "100%" }}>
        <div className="searchCompany">
          <AutoSuggestComponent
            getCompany={this.getCompany}
            toggleModal={this.toggleModal}
          />
        </div>
        <GoogleMapReact
          onChange={this._onChange}
          defaultCenter={this.state.center}
          defaultZoom={3}
          zoom={this.state.zoom}
          bootstrapURLKeys={{ key: config.googleapiKey }}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) => this.apiIsLoaded(map, maps)}
        >
          {markers}
        </GoogleMapReact>
        <div className="lowerDivMap" onClick={this.fetchAllCompaniesInView}>
          <button className="badge lowerDivMapButton">K</button>
        </div>
      </div>
    );
  }
}

export default geolocated({
  positionOptions: {
    enableHighAccuracy: true
  },
  userDecisionTimeout: 10000
})(MapComponent);
