import React from "react";
import "../../App.css";
import moment from "moment";

export default class Day extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      todayAppointments: 0,
      countGenerated: false,
      ...this.state,
      ...props
    };
  }
  componentWillReceiveProps(newprops) {
    if (newprops !== this.props) {
      this.setState({
        ...this.state,
        ...newprops
      });
      this.setState({
        countGenerated: false
      });
      this.appointmentsOnDays();
    }
  }
  appointmentsOnDays = () => {
    var appointments = this.props.appointments;
    let todayAppointmentsCount = 0;
    if (
      typeof appointments !== "undefined" &&
      appointments != null &&
      this.state.day !== "undefined" &&
      this.state.day != null &&
      !this.state.countGenerated &&
      appointments.length > 0
    ) {
      for (var i = 0; i < appointments.length; i++) {
        var appointmentDate = appointments[i].starttime;
        var appointmentDateFormat = moment(appointmentDate).format(
          "MMM Do YYYY"
        );
        var date = this.state.day.date;
        date = moment(date).format("MMM Do YYYY");
        if (appointmentDateFormat === date) {
          todayAppointmentsCount++;
          this.setState({
            todayAppointments: todayAppointmentsCount
          });
        }
      }
      this.setState({
        countGenerated: true
      });
    }
  };

  render() {
    const {
      day,
      day: { date, isCurrentMonth, isToday, number },
      select,
      selected
    } = this.props;
    return (
      <span
        className={
          "daySpan" +
          (isToday ? " today" : "") +
          (isCurrentMonth ? "" : " different-month") +
          (date.isSame(selected) ? " selected" : "")
        }
      >
        <span key={date.toString()} className="day" onClick={() => select(day)}>
          {number}
        </span>
        {this.state.todayAppointments > 0 && (
          <span className="dot">{this.state.todayAppointments}</span>
        )}
      </span>
    );
  }
}
